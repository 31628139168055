import {format, formatDistance, differenceInDays, parseISO} from 'date-fns'
import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import AuthorList from './author-list'
import EffectTable from './effect-table'

import styles from './ingredient-post.module.css'
import IngredientScore from './ingredient-score'

function IngredientPost (props) {
  const {_rawUsage, _rawHealthImpacts, effects, score, warnings, authors, categories, name, mainImage, publishedAt, otherNames, derivedFrom,type} = props
  let {sources} = props
  console.log(effects)
  effects.flatMap(effect=>effect.sources).forEach(
    effectSource => {
        if(sources.filter(node=>node.source.url==effectSource.source.url).length == 0) {
          sources.push(effectSource)
        }
    }
  )
  console.log(effects)
  return (
    <article className={styles.root}>
      
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.name}>{name}</h1>
            <div className={styles.contentGrid}>
            {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage)).maxWidth(400).auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      {(otherNames || derivedFrom || type || score) && <table className={styles.mainTable}>
      <tbody>{score && <tr>
              <th>Score</th>
              <td><IngredientScore score={score} /></td>
              </tr>}
                {otherNames && otherNames.length > 0 && <tr>
                    <th>Also known as</th>
                    <td>{otherNames.map(name=><p key={name}>{name}</p>)}</td>
                    </tr>}
                    {derivedFrom && derivedFrom.length > 0 && <tr>
                        <th>Derived from</th>
                        <td>{derivedFrom.map(from=><p key={from}>{from}</p>)}</td>
                        </tr>}
                        {type && <tr>
                            <th>Type</th>
                            <td>{type}</td>
                            </tr>}
                            </tbody>
                </table>}
                </div>
            {_rawUsage && <><h3>What is {name.toLowerCase()} used for?</h3><PortableText blocks={_rawUsage} /></>}
            {_rawHealthImpacts && <><h3>Are there any health concerns when using {name.toLowerCase()}?</h3><PortableText blocks={_rawHealthImpacts} /></>}
            {effects && effects.length > 0 && <EffectTable effects={effects} title={name+" Effect Matrix"}/>}
            {warnings && warnings.length > 0 && <><h4>Warnings from regulatory agencies regarding {name.toLowerCase()}</h4><ul>{warnings.map(warning=><li><a href={warning.source.url}>{warning.source.title}</a></li>)}</ul></>}
            {sources && sources.length > 0 && <><h5>Sources</h5><ol>{sources.map(source=><li><a href={source.source.url}>{source.source.title}</a></li>)}</ol></>}
          
          </div>
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                  Last updated&nbsp;
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? formatDistance(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM do, yyyy')}
              </div>
            )}
              
            {authors && authors.length > 0 && <AuthorList items={authors} title='Authors' />}
            {categories && categories.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container>
    </article>
  )
}

export default IngredientPost
