import React from 'react'

import tableStyles from './effect-table.module.css'


const Magnitude = ({magnitude, switchactive}) => {
    return <>{magnitude == 0 && "-"}
    {switchactive && <>
        {magnitude > 0 && range(1,magnitude).map(_=><div className={tableStyles.arrowup}></div>)}
        {magnitude < 0 && range(1,magnitude+5).map(_=><span className={tableStyles.arrowdown}></span>)}
    </>}
    {!switchactive && <>
        {magnitude < 0 && range(1,magnitude+5).map(_=><span className={tableStyles.arrowdown}></span>)}
        {magnitude > 0 && range(1,magnitude).map(_=><div className={tableStyles.arrowup}></div>)}
    </>}
    {switchactive && <>
        {magnitude > 0 && range(1,5-magnitude).map(_=><div className={tableStyles.arrowuplighter}></div>)}
        {magnitude < 0 && range(1,5-(magnitude+5)).map(_=><span className={tableStyles.arrowdownlighter}></span>)}
    </>}
    {!switchactive && <>
        {magnitude < 0 && range(1,5-(magnitude+5)).map(_=><span className={tableStyles.arrowdownlighter}></span>)}
        {magnitude > 0 && range(1,5-magnitude).map(_=><div className={tableStyles.arrowuplighter}></div>)}
    </>}
    </>
}
function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }
const EffectTable = ({effects, title}) => {
    return <><h3>{title}</h3><table className={tableStyles.table}>
    <thead>
      <tr className={tableStyles.longrow}>
          <td>Effect</td>
          <td>Magnitude</td>
          <td>Cosistency</td>
          <td>Notes</td>
      </tr>
      </thead>
      <tbody>
      {effects.map(node=><>
      <tr className={tableStyles.headerrow} key={node.effect.title+"title"}>
          <td>Effect</td>
      </tr>
      <tr className={tableStyles.shortrow}  key={node.effect.title}>
            <td>
                <div className={tableStyles.flextable}>
                    <div className={tableStyles.row}>
                        <div className={tableStyles.cell}>Outcome</div>
                        <div className={tableStyles.cell}>{node.effect.title}</div>
                    </div>
                    <div className={tableStyles.row}>
                        <div className={tableStyles.cell}>Magnitude</div>
                        <div className={tableStyles.cell}>
                            <Magnitude {...node} />
                            </div>
                    </div>
                    <div className={tableStyles.row}>
                        <div className={tableStyles.cell}>Consitency</div>
                        <div className={tableStyles.cell}>{node.consistency}</div>
                    </div>
                    <div className={tableStyles.row}>
                        <div className={tableStyles.cell}>Notes</div>
                        <div className={tableStyles.cell}>{node.notes}</div>
                    </div>
                </div>
            </td>
            </tr></>)}
        {effects.map(node=><tr key={node.effect.title} className={tableStyles.longrow}>
            <td>{node.effect.title}</td>
            <td><Magnitude {...node} switchactive={true} /></td>
            <td>{node.consistency}</td>
            <td>{node.notes}</td>
            </tr>)}
      </tbody>
  </table></>
  }
  
  export default EffectTable
