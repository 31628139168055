import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import IngredientPost from '../components/ingredient-post'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {toPlainText} from '../lib/helpers'

export const query = graphql`
  query IngredientTemplateQuery($id: String!) {
    ingredient: sanityIngredient(id: {eq: $id}) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      name
      slug {
        current
      }
      derivedFrom
      otherNames
      type
      score
      effects {
          effect {
            title
            description
          }
          magnitude
          consistency
          notes
          sources {
          source{
            title
            url
          }
        }
      }
      sources {
        source{
          title
          url
        }
          
      }
      warnings {
        source{
          title
          url
        }
          
      }
      _rawUsage(resolveReferences: {maxDepth: 5})
      _rawHealthImpacts(resolveReferences: {maxDepth: 5})
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
  }
`

const IngredientTemplate = props => {
  const {data, errors} = props
  const ingredient = data && data.ingredient
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {ingredient && <SEO title={ingredient.name || 'Untitled'} description={toPlainText(ingredient._rawExcerpt)} image={ingredient.mainImage} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {ingredient && <IngredientPost {...ingredient} />}
    </Layout>
  )
}

export default IngredientTemplate
